import React from 'react';
import classNames from 'classnames';

import SectionHeader from '../components/sections/partials/SectionHeader';

class ContactUsView extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap center-content', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Contact Us',
      paragraph: 'If you have any questions, feedback, or inquiries, we\'re here to help. Please feel free to contact us at the email address below. We strive to respond promptly to all messages.',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16"></div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Email</h4>
                    <p className="m-0 text-sm">Feel free to reach out to us at:</p>
                    <p className="m-0 text-sm">info@softmediatec.de</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUsView;
