import React from 'react';
import classNames from 'classnames';

import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from '../components/elements/Image';




class TermsView extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap center-content', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Terms & Conditions',
      paragraph:
        'Welcome to Aimify, These Terms and Conditions ("Terms") govern your use of the Aimify mobile application ("App"). By accessing or using the App, you agree to be bound by these Terms and all applicable laws and regulations. If you disagree with any of these Terms, you are prohibited from using the App.',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Privacy and Data Use</h4>
                    <p className="m-0 text-sm">
                    We take your privacy seriously. We do not share any personal information or contact information with any third party. We use the data you provide only for the purpose of the App and its features. This includes:

Enabling you to set and track your goals.
Connecting you with relevant coaching resources, both AI and human, based on your needs and consent.
Providing anonymized data insights to improve the App.
                    </p>
                  </div>
                  
                  
                </div>
              </div>

    <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">AI Coach and Personal Information</h4>
                    <p className="m-0 text-sm">
                    The App employs an AI coach powered by OpenAI GPT. By interacting with the AI coach, you agree that your questions and the AI's responses will be processed by OpenAI GPT, but your personal information will not be shared. Please refer to the OpenAI GPT privacy policy for further details:<a style={{color:'blue'}} href='https://openai.com/policies/privacy-policy'> privacy-policy</a>  
                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Human Coaching and Confidentiality</h4>
                    <p className="m-0 text-sm">
                    Any interaction with human coaches within the App occurs based on your explicit consent. We encourage you to carefully review the privacy policies of any individual coaches before engaging with them.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Google Authentication and Calendar Permission</h4>
                    <p className="m-0 text-sm">
                    You may choose to use Google Authentication to create an account and sign in to the App. By doing so, you grant us access to your basic Google profile information (name and email address). We use this information only for account creation and verification purposes.

Additionally, you may give the App permission to access your Google Calendar. This permission is solely used to sync your "Day to day" plan with your desired Google calendar. You can revoke this permission at any time through your phone settings.
                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Intellectual Property</h4>
                    <p className="m-0 text-sm">
                    All contents of the App, including but not limited to text, graphics, logos, icons, images, audio clips, video clips, data compilations, and software, are the property of SoftmediaTec Gmbh or its licensors and are protected by copyright, trademark, and other intellectual property laws.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Disclaimer of Warranties</h4>
                    <p className="m-0 text-sm">
                    The App is provided "as is" and without warranties of any kind, express or implied. We disclaim all warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the App will be error-free, bug-free, or uninterrupted.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Limitation of Liability</h4>
                    <p className="m-0 text-sm">
                    We shall not be liable for any damages, including, but not limited to, direct, indirect, incidental, consequential, or punitive damages, arising from or related to your use of the App.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Limitation of Liability</h4>
                    <p className="m-0 text-sm">
                    Aimify shall not be liable for any damages arising from your use of our services, including but not limited to, direct, indirect, incidental, or consequential damages.
                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Termination</h4>
                    <p className="m-0 text-sm">
                    We may terminate your access to the App or any part of it for any reason, without notice.
                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Changes to the Terms</h4>
                    <p className="m-0 text-sm">
                    We may revise these Terms at any time by updating this posting. You are bound by any revisions and should therefore periodically visit this page to review the current Terms.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Governing Law</h4>
                    <p className="m-0 text-sm">
                    These Terms shall be governed by and construed in accordance with the laws of Germany, without regard to its conflict of laws provisions.                    </p>
                  </div>
                  
                  
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Contact Us</h4>
                    <p className="m-0 text-sm">
                    If you have any questions about these Terms, please contact us at info@softmediatec.de.
                    </p>
                  </div>
                  
                  
                </div>
              </div>
              
              {/* Add more content sections using the same format */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}



export default TermsView;
